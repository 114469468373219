.main {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/hero.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 93.4vh;
}

h1 {
  margin: 0;
  text-align: center;
  color: white;
}
h4 {
  text-align: center;
  color: white;
}
